<template>
  <div class="product-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Collection" route="website.collections" />
        <div class="page-header">
          <h2 class="page-title">{{ name }}</h2>
        </div>
        <div>
          <v-btn v-if="attributes.published == false" class="mr-3 pointer" @click="attributes.published = true">
            <i class="far fa-eye"></i>
            Enable collection
          </v-btn>
          <v-btn v-else class="mr-3 pointer" @click="attributes.published = false">
            <i class="far fa-eye-slash"></i>
            Disable collection
          </v-btn>
          <v-btn v-if="attributes.published == true" @click="gotoStore" class="pointer">
            <i class="show-password far fa-eye fz-16 mr-1"></i>
            View
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- <GroupSubmit mode="edit" :isLoading="isLoading" :callbackCancel="onCancel" :callbackSubmit="onSubmit" /> -->
    <v-row v-if="!loading">
      <v-col cols="12">
        <Notification type="error" :messages="errors" v-if="errors.length > 0" />
      </v-col>
      <v-col cols="12" sm="8" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <InputDefault :model="['attributes', 'title']" fieldName="Title" label="Title" :isValidate="true" />

          <ContentInput :v-model="attributes.bodyHtml" title="Description" subtitle="Maximum 100.000 characters" />
        </div>
        <div class="elevation-form pa-3 mt-3">
          <InputDefault
            :model="['attributes', 'metaDescription']"
            fieldName="Title"
            :height="150"
            label="Meta Description"
            :isValidate="true"
            :min="0"
            :max="160"
            typeComponent="metaDes"
          />
        </div>
        <div v-if="attributes.collectionType === 'smart'" class="elevation-form pa-3 mt-3">
          <CollectionType />
        </div>
        <!-- ################################################## Products -->
        <div class="elevation-form pa-3 form-component mt-3" v-if="attributes.collectionType !== 'smart'">
          <h3 class="pb-2">Products</h3>
          <div>
            <v-select
              v-model="filter"
              item-text="name"
              item-value="value"
              :items="filters"
              @change="onFilterProduct"
            ></v-select>
            <v-text-field
              v-model="keySearchProduct"
              @input="keySearchProduct !== '' ? (showModalSelectProduct = true) : null"
              placeholder="Search product"
            ></v-text-field>
            <div v-if="isManualCollection">
              <v-col cols="12" class="d-flex align-center" aria-hidden v-if="!selected.length && isManualCollection">
                <v-checkbox v-model="checkAll" hide-details class="cb-nocss"></v-checkbox>
                <label class="mr-2">Position</label>
                <label class="mr-2">Images</label>
                <label class="mr-2">Product title</label>
              </v-col>
              <v-col cols="12" class="d-flex align-center" aria-hidden v-else>
                <div class="action-box d-flex align-center">
                  <v-checkbox v-model="checkAll" hide-details class="cb-nocss" v-if="isManualCollection"></v-checkbox>
                  <label class="mr-2">{{ selected.length }} Products selected</label>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on">
                        Actions
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="reorder('min')">
                        <v-list-item-title>Move to top</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="reorder('max')">
                        <v-list-item-title>Move to bottom</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </div>
            <ul class="list-product">
              <draggable v-model="productList" tag="v-col" :options="draggableOptions">
                <li v-for="(i, k) in productList" :key="i._id">
                  <div class="d-flex align-center py-4" style="position: relative; padding-right: 32px;">
                    <v-checkbox v-model="selected" :value="i._id" v-if="isManualCollection"></v-checkbox>
                    <h4 class="pr-2">{{ k + 1 }}</h4>
                    <v-avatar tile size="40" class="ml-3">
                      <img
                        :src="
                          i.images && i.images.length > 0
                            ? imageHelpers.url(i.images[0].src)
                            : '@/assets/images/no-image.png'
                        "
                        alt=""
                      />
                    </v-avatar>
                    <router-link class="ml-3 link-add" :to="{ name: 'website.products.update', params: { id: i._id } }">
                      {{ `${i.title} (${i.totalSales || 0} sales)` }}
                    </router-link>
                    <button
                      style="position: absolute;right: 0; top: 50%; transform: translateY(-50%); z-index: 1;"
                      @click="removeProductToCollection(i)"
                    >
                      <span class="mdi mdi-trash-can-outline mdi-18px"></span>
                    </button>
                  </div>
                  <v-divider />
                </li>
                <li v-if="validLoadMore && productList.length" class="text-center mt-3">
                  <v-btn @click="loadMore" :loading="isLoadingMore" class="btn-link">
                    <span class="mdi mdi-eye mdi-18px mr-2"></span> Show more product</v-btn
                  >
                </li>
              </draggable>
            </ul>
          </div>
          <div class="text-center" v-if="!productList || productList.length == 0">
            <img :src="imageFake.Emty" class="mb-2" width="50px" />
            <p>There are no products in this collection</p>
          </div>
        </div>

        <div class="mt-4">
          <v-col cols="12" sm="12" xs="12" class="pa-3 elevation-form">
            <template>
              <v-row justify="center">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <div>
                        <h3>Search engine listing preview</h3>
                        <p class="mt-5">
                          Add a title and description to see how this collection might appear in a search engine listing
                        </p>
                      </div>
                      <template v-slot:actions>
                        <span class="btn-link">Edit website SEO</span>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template>
                        <div>
                          <span>Page title</span>
                          <v-text-field v-model="attributes.metafieldsGlobalTitleTag"></v-text-field>
                        </div>
                        <div>
                          <span>Meta description</span>
                          <v-textarea v-model="attributes.metafieldsGlobalDescriptionTag"></v-textarea>
                        </div>
                        <div>
                          <span>URL and handle</span>
                          <v-text-field v-model="attributes.metafieldsGlobalUrl" :prefix="domain"></v-text-field>
                        </div>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </template>
          </v-col>
        </div>
      </v-col>
      <!-- ########################################################################## RIGHT -->
      <v-col cols="12" sm="4" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <CollectionAvailability />
        </div>
        <div class="elevation-form pa-3 mt-4">
          <h4 class="mb-3">Collection image</h4>
          <UploadOneImage :image="attributes.image" @callback="getImageUpLoad" />
        </div>
        <div class="elevation-form pa-3 mt-4">
          <h4 class="mb-3">Add collection to navigation</h4>
          <div>
            <span>
              The collection will appear in the selected menu items under Top Navigation
            </span>
          </div>
          <v-select :return-object="true" v-model="menuCollection" multiple :items="menus" item-text="title"></v-select>
          <div>
            <v-chip
              v-for="(i, k) in menuCollection"
              :key="i.title + k"
              class="mb-2 mx-2"
              @click:close="removeChip(k)"
              close
              hide-selected
              small
              label
              multiple
            >
              {{ i.title }}
            </v-chip>
          </div>
          <div @click="$router.push(`/admin/menus`)" class="pointer">
            Edit navigation
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-btn @click="showDialogDelete = true" class="mr-3" color="error" elevation="">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
        <v-btn :loading="isLoading" color="primary" @click="onSubmit">Save</v-btn>
      </v-col>
    </v-row>
    <div style="width: 100%; height: 100%" v-if="loading" class="d-flex justify-center align-center">
      <page-loader />
    </div>
    <BeforeLeavePage />
    <delete-action :content="'collection ' + name" :title="'Delete ' + name" />
    <v-dialog persistent v-model="showModalSelectProduct" width="700" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline">
          {{ 'Select products' }}
        </v-card-title>
        <v-row no-gutters d-flex justify="center">
          <v-col cols="11">
            <v-text-field autofocus class="px-4" v-model="keySearchProduct"></v-text-field>
          </v-col>
        </v-row>

        <!-- Close icon -->
        <div class="close-icon" @click="showModalSelectProduct = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <div class="list-product" v-if="!isLoadingProduct">
          <!-- <div class="py-3 my-2">
            <v-divider></v-divider>
          </div> -->
          <div v-for="(i, k) in productSelectList" :key="i._id" class="px-4 px-12">
            <!-- <span class="mr-5 fz-18">{{ k + 1 }}</span> -->
            <div class="d-flex align-center">
              <v-checkbox v-model="listIdProductAddCollection" :value="i._id" class="mr-4"></v-checkbox>
              <v-avatar tile size="40">
                <img
                  :src="
                    i.images && i.images.length > 0 ? imageHelpers.url(i.images[0].src) : '@/assets/images/no-image.png'
                  "
                  alt=""
                />
              </v-avatar>
              <router-link class="ml-3 link-add" :to="{ name: 'website.products.update', params: { id: i._id } }">
                {{ `${i.title} (${i.totalSales} sales)` }}
              </router-link>
            </div>

            <v-divider v-if="k + 1 < productSelectList.length" class="mt-2"></v-divider>
          </div>
          <div v-if="!isLoadingProduct && productSelectList.length === 0" class="d-flex flex-column align-center">
            <img :src="imageFake.Emty" alt="" srcset="" width="50px" />
            <div>No Product</div>
          </div>
        </div>
        <div style="width: 100%; height: 100%" v-else class="d-flex justify-center align-center my-3">
          <page-loader />
        </div>

        <v-divider class="mb-3"></v-divider>
        <!-- Footer -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="(showModalSelectProduct = false), getProduct()"> Close </v-btn>
          <v-btn :loading="loadingSelect" color="primary" @click="submitAddProductToCollection"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import resourceBeforeLeavePage from '@/mixins/before-change-router';
import { collectionApi } from '@/apis/collection';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import ContentInput from '@/views/website/components/Editor';
import CollectionAvailability from './components/CollectionAvailability';
import CollectionType from './components/CollectionType';
import { productApi } from '@/apis/product';
import draggable from 'vuedraggable';
import { menuService } from '@/apis/menu.s';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    ContentInput,
    CollectionAvailability,
    CollectionType,
    draggable,
  },
  data() {
    let defaultForm = {
      title: '',
      bodyHtml: null,
      image: {
        src: 'https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg',
        position: 1,
      },
      rules: [{ column: 'tags', relation: 'equal', condition: null }], // [{column,relation,condition}]
      collectionType: 'smart',
      handle: '',
      disjunctive: true,
      upsellOffer: true,
      upsellWidget: true,
    };
    return {
      imageFake,
      menuCollection: [],
      listIdProductAddCollection: [],
      isLoadingProduct: false,
      keySearchProduct: '',
      showModalSelectProduct: false,
      imageHelpers,
      collectionType: '',
      showDialogDelete: false,
      name: '',
      idItem: null,
      isLoading: false,
      loading: false,
      // page: {},
      showHeader: false,
      loadingSelect: false,
      attributes: Object.assign({}, defaultForm),
      defaultForm,
      listVariants: [],
      // Product options
      options: [], // need
      productList: [],
      productSelectList: [],
      errors: [],
      validLoadMore: true,
      isLoadingMore: false,
      page: 1,
      filter: '',
      sort: '',
      domain: `${localStorage.getItem(`store-domain`)}/`,

      filters: [
        {
          name: 'Product title A-Z',
          value: 'alphaAsc',
        },
        {
          name: 'Product title Z-A',
          value: 'alphaDesc',
        },
        {
          name: 'Highest price',
          value: 'priceAsc',
        },
        {
          name: 'Lowest price',
          value: 'priceDesc',
        },
        {
          name: 'Newest',
          value: 'createdAsc',
        },
        {
          name: 'Oldest',
          value: 'createdDesc',
        },
        {
          name: 'Manual',
          value: 'manual',
        },
        {
          name: 'Top sales',
          value: 'totalSalesDesc',
        },
      ],
      draggableOptions: { disabled: true },
      selected: [],
      checkAll: false,
      menus: [],
    };
  },
  async created() {
    try {
      this.loading = true;
      this.idItem = this.$route.params.id;
      let res = (await collectionApi.getById(this.idItem)).data.collection;
      let m = await menuService.getMenuByCollectionId(this.idItem);
      this.menuCollection = m.data;
      console.log(m, 'mmmm');
      this.name = res.title;
      this.attributes = {
        bodyHtml: res.bodyHtml,
        title: res.title,
        image: res.image,
        published: res.published,
        publishedScope: res.publishedScope,
        collectionType: res.collectionType,
        handle: res.handle,
        rules: res.rules,
        metafieldsGlobalTitleTag: res.metafieldsGlobalTitleTag,
        metafieldsGlobalDescriptionTag: res.metafieldsGlobalDescriptionTag,
        metafieldsGlobalUrl: res.metafieldsGlobalUrl,
        disjunctive: res.disjunctive,
        metaDescription: res.metaDescription,
        upsellOffer: res.upsellOffer || res.upsellOffer === undefined ? true : false,
        upsellWidget: res.upsellWidget || res.upsellWidget === undefined ? true : false,
        sortOrder: res.sortOrder,
      };
      this.filter = this.filters.find(filter => filter.value == res.sortOrder)?.value;
      this.draggableOptions.disabled = this.filter == 'manual';
      this.getProduct();
      this.$forceUpdate();
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
    this.getMenus();
  },
  methods: {
    removeChip(k) {
      this.menuCollection.splice(k, 1);
    },
    async getMenus() {
      try {
        let menuData = await menuService.list();
        this.menus = menuData.data;
        console.log(this.menus);
        this.isLoading = false;
      } catch (error) {
        console.log(`error`, error);
        this.errors = error.response.data.message || [];
      }
    },
    onFilterProduct() {
      if (this.filter == 'manual') {
        this.draggableOptions.disabled = false;
      }
      this.attributes.sortOrder = this.filter;
      this.sort = this.filter;
      this.page = 1;
      this.getProduct();
    },
    gotoStore() {
      window.open('https://' + this.$store.state.auth.accountInfo.rDomain + `/collections/${this.attributes.handle}`);
    },
    async removeProductToCollection(id) {
      try {
        console.log(id, 'id');
        await collectionApi.removeProductToCollection(this.$route.params.id, id._id);
        await this.getProduct();
      } catch (error) {
        //empty
      }
    },
    async submitAddProductToCollection() {
      try {
        this.loadingSelect = true;
        await collectionApi.addProductToCollection(this.$route.params.id, this.listIdProductAddCollection);
        await this.getProduct();
        this.showModalSelectProduct = false;
        this.keySearchProduct = '';
      } catch (error) {
        console.log(error, error.response);
      }
      this.loadingSelect = false;
      this.showModalSelectProduct = false;
    },
    async submitDelete() {
      try {
        collectionApi.remove(this.idItem);
        this.$router.push({ name: 'website.collections' });
      } catch (error) {
        console.log(error);
      }
    },
    getImageUpLoad(images) {
      this.attributes.image = images;
    },
    async loadMore() {
      this.page++;
      this.isLoadingMore = true;
      try {
        let params = {
          limit: 10,
          page: this.page,
          sort: this.sort,
        };
        let res = await productApi.getByCollectionId(this.$route.params.id, params);
        this.validLoadMore = res.data.products.length == 0 || res.data.products.length < 10 ? false : true;
        this.productList = [...this.productList, ...res.data.products];
        this.listIdProductAddCollection = this.productList.map(item => item._id);
        this.isLoadingMore = false;
      } catch (error) {
        this.isLoadingMore = false;
        console.log(error);
      }
    },
    async getProduct() {
      try {
        let params = {
          limit: 10,
          page: this.page,
          sort: this.filter,
        };
        let res = await productApi.getByCollectionId(this.$route.params.id, params);
        this.validLoadMore = res.data.products.length == 0 || res.data.products.length < 10 ? false : true;
        // console.log(this.productList);
        this.productList = res.data.products;
        this.listIdProductAddCollection = res.data.products.map(item => item._id);
      } catch (error) {
        console.log(error);
      }
    },
    onCancel() {
      this.$v.$reset();
      this.attributes = Object.assign({}, this.defaultForm);
      this.attributes.rules = [{ column: 'tag', relation: '', condition: null }];
      this.$forceUpdate();
      //website.collections
    },
    async onSubmit() {
      this.$v.$touch();
      utilities.scrollToTop();
      const isErrosCondition =
        this.attributes && this.attributes.rules ? this.attributes.rules.find(item => item.relation === '') : false;
      try {
        if (!this.$v.$invalid && !isErrosCondition) {
          this.errors = [];
          this.isLoading = true;
          if (this.attributes && !this.attributes.published) {
            this.attributes.published = false;
          }
          let convertData = {
            ...this.attributes,
            rules: this.attributes.collectionType === 'normal' ? null : this.attributes.rules,
          };
          let res = await collectionApi.update(this.idItem, convertData);
          if (this.filter == 'manual' || this.filter?.value == 'manual') {
            await this.updateProductSort();
          }
          this.isLoading = true;
          // this.attributes.menuCollection
          console.log('this.menuCollection', this.menuCollection);
          try {
            for (let i = 0; i < this.menus.length; i++) {
              let mWm = this.menuCollection.find(menu => this.menus[i].id === menu.id);
              console.log(`mWm`, mWm);
              if (mWm) {
                await menuService.updateMenuForCollection(mWm.id, this.idItem);
              } else {
                await menuService.deleteMenuForCollection(this.menus[i].id, this.idItem);
                //remove
              }
            }
          } catch (error) {
            console.log('error', error);
          }

          // window.location.reload();
        } else {
          this.errors = ['Validate rule error'];
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async updateProductSort() {
      let arrPosition = this.productList.map(product => product?.orderInCollection[this.idItem]);
      arrPosition = arrPosition.sort((a, b) => a - b);
      for (let index = 0; index < this.productList.length; index++) {
        const product = this.productList[index];
        if (!product?.orderInCollection || product?.orderInCollection[this.idItem] !== arrPosition[index]) {
          let orderInCollection = product?.orderInCollection;
          if (!orderInCollection) {
            orderInCollection = { [this.idItem]: arrPosition[index] };
          } else {
            orderInCollection[this.idItem] = arrPosition[index];
          }
          product.orderInCollection = orderInCollection;
          await productApi.updatePositionInCollection(product._id, { orderInCollection: product.orderInCollection });
        }
      }
    },
    async reorder(position) {
      await collectionApi.reorder(this.idItem, {
        productIds: this.selected,
        sortType: position,
      });
      this.selected = [];
      window.location.reload();
    },
  },
  watch: {
    keySearchProduct() {
      this.isLoadingProduct = true;
      let params = {
        page: 1,
        limit: 100,
        search: this.keySearchProduct,
      };
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(async () => {
        let res = await productApi.get(params);
        this.productSelectList = res.data.products;
        this.$forceUpdate();
        this.isLoadingProduct = false;
      }, 300);
    },
    attributes: {
      handler: function(val, oldVal) {
        this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
      },
      deep: true,
    },
    selected: function() {},
    checkAll: function(val) {
      if (!this.checkAll) this.selected = [];
      else {
        this.productList.map(product => {
          if (!this.selected.includes(product._id)) this.selected.push(product._id);
        });
      }
    },
    filter: function(val) {
      if (val == 'manual') {
        this.draggableOptions.disabled = false;
      }
    },
  },
  computed: {
    isManualCollection: function() {
      return this.filter == 'manual' || this.filter.value;
    },
  },
  validations: {
    attributes: {
      title: {
        required,
      },
      metaDescription: {
        maxLength: maxLength(160),
      },
      rules: {
        $each: {
          condition: { required },
          column: { required },
          relation: { required },
        },
      },
    },
  },
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .list-product {
    max-height: 500px;
    overflow: auto;
    padding-left: 0;
    list-style: none;
    li {
      display: block;
    }
  }

  .action-box {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-left: 8px;
    padding-right: 8px;
  }

  .cb-nocss {
    margin-top: 0px;
    padding: 10px;
  }

  .btn-action {
  }
}
</style>
