import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/onlinestore/menus/';

function list() {
  return storeRequest.get(path);
}

function get(id) {
  return storeRequest.get(path + id);
}

function create(menu) {
  return storeRequest.post(path, menu);
}

function update(menu) {
  return storeRequest.put(path + menu.id, menu);
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}
function getMenuByCollectionId(idCollection) {
  return storeRequest.get(`${path}/collections/` + idCollection);
}
function updateMenuForCollection(idMenu, idCollection, data) {
  return storeRequest.post(`${path}${idMenu}/collections/${idCollection}`);
}
function deleteMenuForCollection(idMenu, idCollection, data) {
  return storeRequest.delete(`${path}${idMenu}/collections/${idCollection}`);
}
export const menuService = {
  list,
  create,
  update,
  get,
  deleteById,
  getMenuByCollectionId,
  updateMenuForCollection,
  deleteMenuForCollection,
};
